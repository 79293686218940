/* Primary navigation */
@media (min-width: 0px) {
	#xs-navbar-collapse {
		
		white-space: nowrap;
		box-shadow: inset 0 20px 10px -10px rgba(black, 0.20);
		margin-right: -15px;
		margin-left: -15px;
		max-width: 800px;
		padding: 0 0 20px 0;

		/*Desktop Styling*/
			border: 3px solid rgba(white, 0.15);
			margin-bottom: 3px;
			padding: 0;
			display: block;


		.xs-primary-navigation {
			width: 802px;

			li {
				float: left;
				/*Desktop Styling*/

					border-left: 1px solid rgba(white, 0.25);
					border-right: 1px solid rgba(black, 0.15);

					text-align: center;
					width: 16.55555556%;
					overflow: hidden;

					&.spacer {
						display: block;
						height: 26px; 
						width: 16.55555556%;
					}

					&:first-child {
						border-left: none;
					}

					&:last-child {
						border-right: none;
					}

					a:hover {
						background-color: rgba(white, 0.2);
					}
			}
		}
	} /* /#xs-navbar-collapse */

	.navbar {
		/* Specific height of main nav */
		/*Desktop Styling*/
			min-height: 30px;
	}
	.navbar-nav>li>a {
		/*Desktop Styling*/
			padding: 3px 0;
	}
}