/* Main Content Styles */
@media (min-width: 0px){
	
	main {
		background: white;
		border-radius: 4px 4px 0 0;
	}

	.embed-wrapper, iframe {
	    position: relative;
		z-index: 0;
	}

}