/* Header Styles */
@media (min-width: 0px) {

	header {
		border-radius: 0 0 4px 4px;
		height: 75px;
		margin-bottom: 3px;
		max-width: 800px;
		overflow: hidden;
		position: relative;

		.header-image {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			border: 1px solid rgba(black, 0.25);
			border-top: none;
			border-radius: 0 0 4px 4px;
			height: 72px;
			margin:  0 3px 3px 3px;
			position: relative;
			overflow: hidden;
		}
	}

	.company-logo {
		position: absolute;
		display: none;
	   
		img {
			height: auto;
			width: auto;
		}
	}

	.company-name {
		line-height: 1;
		position: absolute;
		text-decoration: none;
		
		&:hover {
			text-decoration: none;
		}
	}

	.company-motto {
		line-height: 1;
		position: absolute;
	}
}