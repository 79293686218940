/* Sidebar Styles */
@media (min-width: 0px) {
	.xs-sidebar {	
		padding: 0;
		display: table-cell;
		float: none;
		vertical-align: top;
		white-space: nowrap;

		&.show {
			height: auto;
		}

		/*Desktop Styling*/
			height: auto;
			padding: 0;
			border-radius: 4px 0 0 0;

		nav {
			min-height: 500px;
		}
	}


	.xs-profile-picture {
		display: none;
		margin: 1em auto;
		padding: 0;
		text-align: center;


		img {
			height: auto;
			max-width: 100%;
			border: 1px solid black;
		}
	}


	#xs-sidebar {
		padding: 0;

		/* Specific width of sidebar */
		width: 180px;
		min-width: 180px;
		max-width: 180px;

		.xs-secondary-navigation {
			margin: 0 0 2em 0;
		}

		li {
			background: rgba(black, 0.05);
			display: block;
			position: relative;
			width: 180px;

			&.spacer {
				background: transparent;
				height: 24px;
			}

			/* indented items */
			&.indent > a {
				padding-left: 16px;
			}

			/* has sub-nav child */
			&.has-children {
				/* right arrow indicator */
				svg.sub-indicator {
					opacity: 0.25;
					position: absolute;
					top: 6px;
					right: 3px;
				}
			}

			/* 'fly-out' sub-navigation */
			.sub-nav {
				display: none;
				position: absolute;
				top: 0;
				left: 180px;
				z-index: 101;
				margin: 0;
				padding: 0;

				li {	

					a {

						&:hover {
							text-decoration: none;
							background-color: transparent;
						}
					}

					&.indent {
						a {
							padding-left: 12px;
						}
					}
				}
			}

			/* on li:hover
			SHOW .sub-nav */
			&:hover {
				.sub-nav {
					display: block;
				}
			}

			a {
				display: block;
				padding: 2px 6px;
				transition: background .4s;
				width: 180px;
				overflow: hidden;

				svg {
					margin: 0 6px 0 0;
					opacity: 0.5;
					transition: opacity .4s;
				}

				&:hover {
					background-color: transparent;
						svg {
							opacity: 1;
						}
				}

			}
		}

		/* xs-alt-navigation links */
		.xs-alt-navigation {

			li {
				background-color: transparent;
				margin-bottom: 1em;

				a {
					text-indent: -999em;
					background-color: transparent;
					background-repeat: no-repeat;
					background-position: center;
					min-height: 31px;

					/* "Tell a Friend */
                    &[href="/TellaFriend"], &[href="/tellfriend.x"] {
                        background-image: url(/images/tellafriend.png);
                    }
                    
                    /* "Adobe Acrobat */
                    &[href="/DownloadAdobeAcrobat"], &[href="/downloadadobe.x"] {
                        background-image: url(/images/acrobat.png);
                    }
				}
			}
		}/* /.xs-alt-navigation */

		

		/* Language options */
       .xs-language-options {
           background: rgba(white,0.375);
           border: 1px solid rgba(black,0.5);
           font-size: 0.8em;
           margin: 7px 1em 1em 1em;
           padding: 0.5em 0;

           label {
               margin-bottom: 0;
           }

           a {
               color: #337ab7 ;
               font-family: initial;
               font-size: 9.6px;
               font-style: normal;
               font-weight: normal;
               line-height: 13.7143px;
               text-align: center;
               text-decoration: underline;

               &::after {
                   content: "|";
                   display: inline-block;
                   margin: 0 0.25em 0 0.5em;
                   text-decoration: none;
               }

               &:last-child {
                   &::after {
                       display: none;
                   }
               }
           }

       } /* /.xs-language-options */

	}/* /#xs-sidebar */
	
}

