/* Footer Styles */

footer {
	font-size: 8pt;

	a {
		text-decoration: underline;
		color: #0000EE;

		&:hover {
			color: #0000EE;
		}

		&:visited {
			color: #551AAB;
		}
	}

	.xs-footer-text {
		padding: 10px;
	}
}