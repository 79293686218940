/* Layout
/////////////////////// */
html {
	overflow-y: scroll;
}

body {
	background-position: center center;
	background-repeat: repeat;
	font-size: 8pt;
	font-family: Verdana, Arial;
	background-color: #ffffff;
	color: #000000;
}


h1 {
	margin-top: 0;
}

.navbar {
	border: none;

	a {
		transition: all .4s;
	}

}

.navbar-nav {
	margin: 0;
}

.container {
	width: auto;

	&.xs-main-container {
		background: #54524a;
		width: 800px;
		display: table;
		margin: 0 auto;
	}
}

/* Specific width of main content to account for sidebar width */
.col-sm-10 {
	width: 620px;
	display: table-cell;
	float: none;
}

/* Call to Action Styles
/////////////////////// */
#XSCallToAction {
	margin-bottom: 10px;
}

sup {
	font-size: 0.5em;
}


/* Header Styles
/////////////////////// */
@import 'header';

/* Navbar (Primary nav) Styles
/////////////////////// */
@import 'navbar';


/* Main Content Styles
/////////////////////// */
@import 'main';


/* Sidebar Styles
/////////////////////// */
@import 'sidebar';

/* Footer Styles
/////////////////////// */
@import 'footer';
